import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTvMazeShowsQuery ,} from '../actions/tvmazeSlice'; // Assuming the hook name
import {  useGetPopularMoviesQuery } from '../actions/tmdbApiSlice';

import { helix } from 'ldrs';
const placeholderImageUrl = 'https://via.placeholder.com/102x169.png?text=Poster+Not+Available';

helix.register('l-helix');

function HomeScreen() {
  const [tvListData, setTvList] = useState([]);
  const navigate = useNavigate();


  // Function to shuffle an array
  function shuffleArray(array) {
    const newArray = array.slice(); // Create a shallow copy of the array
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }

  const { data, error, isFetching } = useTvMazeShowsQuery();
  const { data:popularMoviesData, error:popularMoviesError, isFetching:isFetchingPopularMovies } = useGetPopularMoviesQuery();
  const popularMovies = popularMoviesData?.results;

  useEffect(() => {
    if (data) {
      // Shuffle the response data array
      const shuffledData = shuffleArray(data);
      // Set the shuffled data
      setTvList(shuffledData);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(`${Object.values(error.data)}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [error]);

  const handleImageClick = (tvItem) => {
    // Handle image click (e.g., navigate to details page)
    const nameYear = `${tvItem.name || tvItem.title} ${tvItem.premiered || tvItem.release_date.split('-')[0]}`;
    navigate(`/details/?q=${nameYear}&mid=${tvItem?.id}`);

  };

  return (
    <Container>
      <Col>
        <Card className="card-background">
          <Card.Body>
            <Row>
              <Col>
                <Card.Title id='logo'>Aircheck</Card.Title>
                <Card.Text>
                  <p>Welcome to the ultimate TV and movie companion app! 🎬🍿
                    Enter the name of your favorite TV show, select the season and episode, and voila! Instantly find out if the episode has aired, along with its release date. If it hasn’t aired yet, no worries! Our app lets you set a reminder for the air date and saves it for you. 📅🔔
                    But that’s not all! You’ll also get a wealth of information about the episode, including the cast, plot, and ratings. 🎭📖⭐
                    So why wait? Dive into the world of entertainment with our app - your one-stop-shop for all things TV and movies! 🌟
                  </p>
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Accordion
      defaultActiveKey="0"
      className='mt-5 mb-3'
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Click Here 👉 for Top picks show case</Accordion.Header>


          <Accordion.Body>

        { isFetching ? (
                    <div className='text-center mt-4'>
                    <l-helix size="150" speed="2.5" color="white"></l-helix>
                  </div>)
:
            <Container style={{ overflow: 'auto' }}>
              <div className="image-slider">
                {tvListData.length > 0 ? (
                  tvListData.map((tvItem, index) => (
                    <div key={index} className="image-container">
                      <img
                        src={tvItem.image.original}
                        alt={tvItem.name}
                        onClick={() => handleImageClick(tvItem)}
                        style={{ cursor: 'pointer' }}
                        loading="lazy"
                      />
                    </div>
                  ))
                ) : (
                  <p>No TV shows available.</p>
                )}
              </div>
            </Container>
}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>






      <Accordion
      className='mt-5 mb-3'
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Click Here 👉 for Popular Movies</Accordion.Header>


          <Accordion.Body>

        { isFetchingPopularMovies ? (
                    <div className='text-center mt-4'>
                    <l-helix size="150" speed="2.5" color="white"></l-helix>
                  </div>)
:
            <Container style={{ overflow: 'auto' }}>
              <div className="image-slider">
              {popularMovies && popularMovies.map(movieItem => (
                    <div key={movieItem.id} className="image-container">
                      <img
                        src={movieItem.poster_path ? `https://image.tmdb.org/t/p/original${movieItem.poster_path}` : placeholderImageUrl}

                        alt={`${movieItem.title || movieItem.name} poster`}

                        onClick={() => handleImageClick(movieItem)}
                        style={{ cursor: 'pointer' }}
                        loading="lazy"
                      />
                    </div>
                  ))
              }
              </div>
            </Container>
}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

export default HomeScreen;
